// Framework
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Enums
import { Level } from "../enums/level.enum";

@Pipe({
    name: "level"
})
export class LevelPipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: Level): string {
        switch (value) {
            case Level.beginner:
                return this.translate.instant("level-pipe.beginner");
            case Level.advanced:
                return this.translate.instant("level-pipe.advanced");
            default:
                return null;
        }
    }
}
