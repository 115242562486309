<div class="container-fluid px-0 event-detail-container">
    <div class="row d-md-flex d-none">
        <div class="col-12 col-md-6 px-3 px-md-0 d-flex justify-content-center align-items-center" [ngClass]="{ 'order-1': odd}">
            <img [src]="event?.image" class="img-fluid">
        </div>
        <div class="col-12 col-md-6">
            <div class="ahz-event-detail-container h-100">
                <div>
                    <div class="ahz-event-detail-head d-flex" [ngClass]="{ 'justify-content-end': odd}">
                        <h2 class="fs-rb text-red font-weight-bold">{{ event?.date | date: 'dd.MM.yyyy' }}</h2>
                        <h2 class="fs-rb font-weight-bold">
                            <span class="px-2">-</span>{{ event?.date | date: 'HH:mm' }} {{ 'event-detail-card.hour' | translate }}
                        </h2>
                    </div>
                    <p class="font-weight-bold mb-5">{{ event?.description}}</p>
                    <p class="white-space">{{ event?.secondaryDescription }}</p>
                </div>
                <div class="mt-5">
                    <app-traffic-lights [status]="event?.availabilityStatus" [horizontalDisplay]="true" [isOdd]="odd" [capacity]="event?.capacity" [numberOfRegistrations]="event?.numberOfRegistrations"></app-traffic-lights>
                    <!-- Level radio buttons -->
                    <div
                        *ngIf="displayLevels === true"
                        class="mt-4"
                        [ngClass]="{ 'd-flex justify-content-end': odd}"
                    >
                        <ng-container *ngTemplateOutlet="levelForm; context: { displayInline: true }"></ng-container>
                    </div>
                    <div [ngClass]="{ 'd-flex justify-content-end': odd}">
                        <button
                            *ngIf="!event?.alreadyBooked && event?.availabilityStatus !== availabilityStatusEnum.red"
                            class="btn btn-primary mt-4"
                            (click)="onRegisterClicked()"
                        >
                            {{ 'event-detail-card.register' | translate }}
                        </button>
                        <button
                            *ngIf="event?.alreadyBooked"
                            class="btn btn-secondary mt-4"
                            (click)="onUnregisterClicked()"
                        >
                            {{ 'event-detail-card.unregister' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-md-none d-flex">
        <div class="col-12 col-md-6 px-3 px-md-0 d-flex justify-content-center align-items-center">
            <img [src]="event?.image" class="img-fluid">
        </div>
        <div class="col-12 col-md-6">
            <div class="ahz-event-detail-container h-100">
                <div>
                    <div class="ahz-event-detail-head d-flex justify-content-center" >
                        <h2 class="fs-rb text-red font-weight-bold">{{ event?.date | date: 'dd.MM.yyyy' }}</h2>
                        <h2 class="fs-rb font-weight-bold">
                            <span class="px-2">-</span>{{ event?.date | date: 'HH:mm' }} {{ 'event-detail-card.hour' | translate }}
                        </h2>
                    </div>
                    <p class="font-weight-bold text-center">{{ event?.description}}</p>
                    <p class="mb-5 text-center white-space">{{ event?.secondaryDescription }}</p>
                </div>
                <div>
                    <!-- Level radio buttons -->
                    <div
                        *ngIf="displayLevels === true"
                        class="mt-4 mb-2"
                    >
                        <ng-container *ngTemplateOutlet="levelForm; context: { displayInline: false }"></ng-container>
                    </div>
                    <div class="d-flex justify-content-center mb-2">
                        <button
                            *ngIf="!event?.alreadyBooked && event?.availabilityStatus !== availabilityStatusEnum.red"
                            class="btn btn-primary mt-4"
                            (click)="onRegisterClicked()"
                        >
                            {{ 'event-detail-card.register' | translate }}
                        </button>
                        <button
                            *ngIf="event?.alreadyBooked"
                            class="btn btn-secondary mt-4"
                            (click)="onUnregisterClicked()"
                        >
                            {{ 'event-detail-card.unregister' | translate }}
                        </button>
                    </div>
                    <app-traffic-lights [status]="event?.availabilityStatus"></app-traffic-lights>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #levelForm let-displayInline="displayInline">
    <form>
        <fieldset [disabled]="event?.alreadyBooked === true">
            <div class="form-check" [ngClass]="{ 'form-check-inline': displayInline === true}">
                <input
                    id="level-beginner"
                    type="radio"
                    name="level"
                    [value]="levelEnum.beginner"
                    [(ngModel)]="levelValue"
                    class="form-check-input"
                >
                <label class="form-check-label" for="level-beginner">{{ levelEnum.beginner | level }}</label>
            </div>
            <div class="form-check" [ngClass]="{ 'form-check-inline mr-0': displayInline === true}">
                <input
                    id="level-advanced"
                    type="radio"
                    name="level"
                    [value]="levelEnum.advanced"
                    [(ngModel)]="levelValue"
                    class="form-check-input"
                >
                <label class="form-check-label" for="level-advanced">{{ levelEnum.advanced | level }}</label>
            </div>
    </fieldset>
    </form>
</ng-template>
