// Framework
import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
// Classes
import { Event } from "../../classes/event.class";
// Enums
import { AvailabilityStatus } from "../../enums/availability-status.enum";
import { Level } from "../../enums/level.enum";
// Services
import { AlertService } from "@src/app/modules/share/services/alert.service";

@Component({
    selector: "app-event-detail-card",
    templateUrl: "./event-detail-card.component.html",
    styleUrls: ["./event-detail-card.component.scss"]
})
export class EventDetailCardComponent implements OnChanges {
    @Input() event: Event = null;
    @Input() odd = false;
    @Output() registerClicked = new EventEmitter<{ eventId: string, level?: Level }>();
    @Output() unregisterClicked = new EventEmitter<string>();
    availabilityStatusEnum = AvailabilityStatus;
    levelEnum = Level;
    levelValue: Level = null;

    get displayLevels(): boolean {
        return this.event?.eventCategory?.categoryName?.toLowerCase() === "schanzer lauftreff" &&
            (this.event?.availabilityStatus !== AvailabilityStatus.red || this.event?.alreadyBooked === true);
    }

    constructor(private alertService: AlertService) { }

    ngOnChanges(): void {
        if (this.event != null) {
            this.levelValue = this.event?.level;
        }
    }

    /**
     * When the register button is clicked, emit the registerClicked event with the event id as param.
     */
    onRegisterClicked(): void {
        // Check if the level needs to be selected. Display an alert if it should be selected and it is null.
        if (this.displayLevels === true && this.levelValue == null) {
            this.alertService.error("event.alert.error-level-not-selected");
        } else {
            this.registerClicked.emit({ eventId: this.event?.id, level: this.levelValue });
        }
    }

    /**
     * When the unregister button is clicked, emit the unregisterClicked event with the event id as param.
     */
    onUnregisterClicked(): void {
        this.unregisterClicked.emit(this.event?.id);
    }
}
