// Framework
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
// Plugins
import { CookieService } from "ngx-cookie-service";
// Classes
import { BaseUser } from "../classes/base-user.class";
// Services
import { SessionApiService } from "../../login-module/services/api/session-api.service";
import { BaseCookieService } from "./templates/base-cookie.service";

interface AppSettingsData {
    currentUser: BaseUser;
    cookieVersion: string;
    currentOrganization: string;
}

@Injectable({
    providedIn: "root"
})
export class AppSettingsService extends BaseCookieService<AppSettingsData> {
    private isLoggedIn = new BehaviorSubject<boolean>(null);

    constructor(private sessionApi: SessionApiService, cookieService: CookieService) {
        super("usaago-frontend-settings", "1.1.0", cookieService);

        if (this.isLoggedIn.value == null) {
            this.sessionApi.getSession()
                .subscribe(
                    () => {
                        this.setIsLoggedIn(true);
                    },
                    (err) => {
                        console.error(err);
                        this.setIsLoggedIn(false);
                    }
                );
        }
    }

    /**
     * This method sets the BehaviorSubject isLoggedIn and update an Observable value.
     */
    setIsLoggedIn(value: boolean): void {
        this.isLoggedIn.next(value);
    }

    /**
     * This method returns the BehaviorSubject isLoggedIn as an Observable
     * @returns isLoggedIn BehaviorSubject as Observable
     */
    getIsLoggedInAsObservable(): Observable<boolean> {
        return this.isLoggedIn.asObservable();
    }

    /**
     * This method saves the given current user in the cookie.
     */
    saveCurrentUser(user: BaseUser): void {
        const appSettingsData = this.loadCookieData();
        appSettingsData.currentUser = user;
        this.saveCookieData(appSettingsData);
    }

    /**
     * This method return the current user from the cookie.
     */
    getCurrentUser(): BaseUser {
        const appSettingsData = this.loadCookieData();
        return appSettingsData.currentUser;
    }

    /**
     * This method saves the given current organization in the cookie.
     */
    saveCurrentOrganization(id: string): void {
        const appSettingsData = this.loadCookieData();
        appSettingsData.currentOrganization = id;
        this.saveCookieData(appSettingsData);
    }

    /**
     * This method return the current organization from the cookie.
     */
    getCurrentOrganization(): string {
        const appSettingsData = this.loadCookieData();
        return appSettingsData.currentOrganization;
    }

    /**
     * This method returns a default initialized AppSettingsData object.
     * @returns default initialized AppSettingsData object
     */
    protected getDefaultCookieData(): AppSettingsData {
        return {
            currentUser: new BaseUser(),
            cookieVersion: this.cookieVersion,
            currentOrganization: null,
        };
    }
}
