// Framework
import { Component, Input } from "@angular/core";
// Enums
import { AvailabilityStatus } from "@src/app/modules/share/enums/availability-status.enum";

@Component({
    selector: "app-traffic-lights",
    templateUrl: "./traffic-lights.component.html",
    styleUrls: ["./traffic-lights.component.scss"]
})
export class TrafficLightsComponent {
    @Input() status: AvailabilityStatus = null;
    @Input() horizontalDisplay: boolean = false;
    @Input() isOdd: boolean = false;
    @Input() capacity: number;
    @Input() numberOfRegistrations: number;
    availabilityStatusEnum = AvailabilityStatus;

    constructor() { }
}
