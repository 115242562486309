<div class="container" [ngClass]="{ 'traffic-lights-vertical': !horizontalDisplay,  'traffic-lights-horizontal': horizontalDisplay}">
    <section class="row justify-content-center">
        <div class="col-12 traffic-lights-info-wrapper w-100"  [ngClass]="{ 'justify-content-end': isOdd}">
            <div class="traffic-lights-wrapper" [class]="status" [ngClass]="{ 'order-1': isOdd}">
                <div class="traffic-light-round"></div>
                <div class="traffic-light-round"></div>
                <div class="traffic-light-round"></div>
            </div>
            <div *ngIf="capacity != null" class="mx-2">
                {{ numberOfRegistrations }} / {{ capacity }} {{ 'traffic-lights.slots-taken' | translate }}
            </div>
            <ng-container *ngIf="capacity == null">
                <div *ngIf="status === availabilityStatusEnum?.green" class="text-center traffic-lights-text" [ngClass]="{ 'pr-3': isOdd}">
                    {{ 'traffic-lights.high-availability' | translate }}
                </div>
                <div *ngIf="status === availabilityStatusEnum?.yellow" class="text-center traffic-lights-text" [ngClass]="{ 'pr-3': isOdd}">
                    {{ 'traffic-lights.low-availability' | translate }}
                </div>
                <div *ngIf="status === availabilityStatusEnum?.red" class="text-center traffic-lights-text" [ngClass]="{ 'pr-3': isOdd}">
                    {{ 'traffic-lights.no-availability' | translate }}
                </div>
            </ng-container>
        </div>
    </section>
</div>
